import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './web_publishing.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid} from '../../components/componentsUtils';
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import SlideTypeG from "../../components/shared/ImageSlider/SlideTypeG";
// import Movie from "../../movies/Inspirations/WebPublishing/inspirations-web-publishing.mp4";
// import WebPublishingIconBlack from "../../images/icons/web_publishing_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
// import YouTubeIconGreen from "../../images/icons/play_video_green.svg";

// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_WebPublishing"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;

// const WebPublishingPage = () => {
//   const { t } = useTranslation();
//   const imagesFluid = getImagesFluid(useStaticQuery(query));
//   const pageTitle = `${getRoutes().WebPublishing.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Pro image quality output in real time'}/>

//       <div className={css.wrapper}>

//         <div className={css.private}>
          
//             <div className={css.wrapper}>
//             <div className={css.slide1}>
//             <CoveredVideo
//                 videoSrcURL={Movie}
//                 placeholderImage={'Web_Publishing_1_placeholder.jpg'}
//                 imagesFluid={imagesFluid}
//                 icon={WebPublishingIconBlack}
//                 title="Web Publishing"
//                 text={<><p>Professional quality output <br/>meeting your expectations.</p><p>No more issues with short deadlines, <br/>peaks in publishing closings</p></>}
//                 buttons={(
//                     <>
//                         <GoToButton
//                             sectionUrl={getLink([getRoutes().WebPublishing, getRoutes().WebPublishing.sections.getStarted])}
//                             text="Get started!"
//                         />
//                         <GoToButton
//                             externalPageUrl={'https://youtu.be/q5tMr9BQSyc'}
//                             icon={YouTubeIconGreen}
//                             filled={false}
//                         />
//                     </>
//                 )}
//             />
//             </div>
//             <div className={css.slide2} id={getRoutes().WebPublishing.sections.getStarted}>
//             <SlideTypeF
//                 title={<>Process <br/>workflow automation</>}
//                 text1={<p>Real time processing increases efficiency.</p>}
//                 text2={<p>High volume productivity.</p>}
//                 text3={<p>Focus on creative to-do tasks.</p>}
//                 fullImage={'web_publishing_2.jpg'}
//                 fullImageAlt={"optimisation artifacts removing upscaling"}
//                 phoneImage1={'Web_publishing_mobile_1.jpg'}
//                 phoneImage1Alt={"optimisation"}
//                 phoneImage2={'Web_publishing_mobile_2.jpg'}
//                 phoneImage2Alt={"artifacts removing"}
//                 phoneImage3={'Web_publishing_mobile_3.jpg'}
//                 phoneImage3Alt={"upscaling"}
//                 imagesFluid={imagesFluid}
//                 imagePosition={'right'}
//             />
//             </div>
//             <div className={css.slide3}>
//             <SlideTypeF
//                 title="Customizable workflow settings"
//                 text1={(
//                     <>
//                         <p>Personal feel and look of image quality output.</p>
//                         <p>Seamless workflow integration.</p>
//                     </>
//                 )}
//                 text3={<p>Auto filling of content metadata.</p>}
//                 fullImage={'web_publishing_3.jpg'}
//                 fullImageAlt={"anonymisation auto cropping metadata"}
//                 phoneImage1={'Web_publishing_mobile_4.jpg'}
//                 phoneImage1Alt={"anonymisation"}
//                 phoneImage2={'Web_publishing_mobile_5.jpg'}
//                 phoneImage2Alt={"auto cropping"}
//                 phoneImage2Height={660}
//                 imagesFluid={imagesFluid}
//             />
//             </div>
//             <div className={css.slide4}>
//             <SlideTypeG
//                 title="Enjoy custom workflow"
//                 fullImage={'web_publishing_4.jpg'}
//                 phoneImage={'Web_publishing_mobile_7.jpg'}
//                 alt= "Custom workflow"
//                 imagesFluid={imagesFluid}
//             />
//             </div>
//         </div>

//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default WebPublishingPage;
